.App {
  background-color: #f2f2f2;
}

.App h1 {
  text-align: center;
}

.App .content {
  width: 800px;
  max-width: 100%;
  margin: auto;
}

.card {
  padding: 1rem;
  box-shadow: 1px 1px 0px 2px #daf2e8;
  border-radius: 4px;
  /* border: 1px solid #daf2e8; */
  background-color: white;
}

.card .title {
  /* font-size: 120%; */
  font-weight: bold;
  margin-bottom: 1rem;
}

span {
  font-family: Montserrat !important;
}

.description {
  display: flex;
  align-items: center;
}

.experience label span:first-child {
  padding: 0.25rem 0 0.25rem 0.5rem;
}

.experience .question:not(:last-child) {
  margin-bottom: 1rem;
}

.experience .question .description {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

:is(.stay-info, .customer-info) .answer > div {
  margin-bottom: 1rem;
}

#send-button:hover {
  background-color: #b579d7;
  border: 0px;
}

#send-button:hover div {
  color: white;
}

@media (min-width: 700px) {
  .inline.question {
    display: flex;
    justify-content: space-between;
  }

  .stay-info .custom-date-picker {
    max-width: calc(50% - 0.5rem);
  }

  .stay-info .inline.question > div {
    width: calc(50% - 0.5rem);
  }

  .experience .question .description {
    margin-bottom: 0.5rem;
  }
}
